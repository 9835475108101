import React, { Component, Fragment } from 'react'
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import { withApollo, compose } from "react-apollo"
import { Link } from "react-router-dom"
import Loader from "../../../../../Material/Loader"
import { Nav, NavItem, NavLink, Label, Popover, PopoverHeader, PopoverBody, Row, Col, Button, UncontrolledTooltip, Progress, Badge } from 'reactstrap'
import '../DriverDetailNew.css'
import TransactionListContainer from "../../../../../Billing/Transactions/TransactionListContainer"
import InvoiceTab from "../InvoiceTab/InvoiceTab"
import UpdateDriverInfo from "../../../../../ApplicationsNew/UpdateDriverInfo"
import PaymentPlanInfo from "../../../../../Billing/Transactions/PaymentPlanInfo"
import DriverNotes from "../../../../../ApplicationsNew/DriverNotes"
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import moment from "moment"
import securityDepositIcon from "../../../../../assets/images/icons/security_deposit_icon.png"
import { HasPermissionsQuery } from "../../../../../Functions/querys"
import { UpdateDriver } from "../../Mutations"
import renderEnumToInt from "../../../../../Functions/renderEnumToInt"
import NotesHistory from "../../../../../Material/NotesHistory/NotesHistory"
import PaymentScheduleModal from "../../../../../Billing/PaymentSchedules/PaymentScheduleModal"
import { PAYMENT_SCHEDULE_STATUS_COLOR_MAP, BILLING_DAYS } from "../../../../../Constants"

const DriverQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        pk
        id
        balance
        hasTokuPaymentReferences
        bcBalance
        currentCharge
        deposit
        refundStage
        balanceWriteOff
        creditWriteOff
        deductible
        autoCharge
        branch{
            id
            tenant{
                id
                customId
            }
        }
        paymentMethod{
            id
            name
        }
        paymentscheduleSet {
            edges {
                node {
                    id
                    status
                    amountToCharge
                    scheduleDatetime
                    dateAdded
                    notes
                    canceledBy {
                        id
                        username
                        firstName
                        lastName
                    }
                    history{
                        date
                        comment
                        user
                    }
                }
            }
        }
        history{
            date
            comment
            user
        }
        currentAgreement{
            id
            agreementpriceSet{
                edges{
                    node{
                        id
                        price
                        interval
                        intervalUnit
                        isActive
                    }
                }
            }
        }
        subSet: subscriptionSet(first:1,status:"1"){
            edges {
              node {
                id
                subType: subscriptionType{
                    id
                    deductibleAmount
                }
              }
            }
        }
        paymentplanSet(status:"1", last:1) {
            edges {
              node {
                id
                status
                paydownAmount
                paydownOriginalBalance
                paydownStartDate
                history {
                    date
                    user
                    comment
                }
              }
            }
        }
        weeklyBillingNotes
        notes
        deposit
        chargeDayofweek
        collectionDay
        chargeCcsurcharge
        chargeTollFee
    }
}`


const UpdateDriverBalanceMutation = gql`
    mutation updateDriverBalance($input: UpdateDriverBalanceInput!){
        updateDriverBalance(input:$input){
            ok
            errors{
                messages
            }
    }
} `

const SentPaymentInstructionsMutation = gql`
    mutation sendCustomerPaymentInstructionsMutation($input: SendCustomerPaymentInstructionsMutationInput!) {
        sendCustomerPaymentInstructionsMutation(input:$input){
            ok
            errors{
                messages
            }
        }
    }
`

class BillingTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subTab: null,
            error: null,
            loading: false
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    refetchBalance = () => {
        this.props.client.mutate({
            mutation: UpdateDriverBalanceMutation,
            variables: { input: { driverId: this.props.driver.id } }
        }).then((result) => {
            if (result.data && result.data.updateDriverBalance && result.data.updateDriverBalance.ok) {
                this.props.refetchDriverQuery()
            }
        })
    }

    sendPaymentInstructions = () => {
        this.props.client.mutate({
            mutation: SentPaymentInstructionsMutation,
            variables: { input: {driverId: this.props.driver.id } }
        }).then((result) => {
            if (result.data && result.data.sendCustomerPaymentInstructionsMutation && result.data.sendCustomerPaymentInstructionsMutation.ok) {

            }
        })
    }

    toggleSwitch = (toggleName, toggleValue, updateDriver = false) => {
        let input = { [toggleName]: toggleValue }
        this.setState({ input: input }, () => {
            if (updateDriver === true) { this.updateDriver(this.props.driver.id, toggleName, toggleValue) }
        })
    }
    updateDriver = (driverId, name, value) => {
        try {
            if (driverId && name && ((typeof value == "boolean" && (value === true || value === false)) || (typeof value !== "boolean" && value))) {
                this.setState({ loading: true })
                let input = {
                    id: driverId,
                    [name]: name === "stage" ? renderEnumToInt(value) : value
                }
                this.props.client.mutate({
                    mutation: UpdateDriver,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateDriver && result.data.updateDriver.ok) {
                        this.setState({ editField: "", loading: false, error: "" })
                        this.props.refetchDriverQuery()

                    } else if (result && result.data && result.data.updateDriver && result.data.updateDriver.errors && result.data.updateDriver.errors[0] && result.data.updateDriver.errors[0].messages) {
                        this.setState({ loading: false, error: result.data.updateDriver.errors[0].messages.toString() })
                    } else {
                        this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: err.message })
                })
            } else if (value === this.state.value) {
                this.setState({ editField: "", loading: false, error: "" })
            }
        } catch (err) {
            this.setState({ loading: false, error: err })
        }
    }

    componentDidMount() {
        if (this.props.location.search && ["transactions", "invoices"].some(tabName => this.props.location.search.includes(tabName))) {
            let queryParams = new URLSearchParams(this.props.location.search)
            this.setState({ subTab: queryParams.get("tab") })
        } else {
            this.props.history.push({ search: new URLSearchParams({ "tab": "transactions" }).toString(), hash: "billing" })
            this.setState({ subTab: "transactions" })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === "billing") {
            this.props.history.push({ search: new URLSearchParams({ "tab": "transactions" }).toString(), hash: "billing" })
            this.setState({ subTab: "transactions" })
        }
    }

    changeTab = (value) => {
        if (value !== this.state.subTab) {
            this.props.history.push({ search: new URLSearchParams({ "tab": value }).toString(), hash: "billing" })
            this.setState({ subTab: value })
        }
    }

    render() {
        let { driver } = this.props
        let subscriptionDeductibleAmount = 0
        if (driver && driver.subSet && driver.subSet.edges && driver.subSet.edges.length > 0) {
            subscriptionDeductibleAmount = driver.subSet.edges[0].node.subType.deductibleAmount
        }
        const statusColorMap = { 'PENDING': 'warning', 'DECLINED': 'danger', 'CANCELED': 'danger', 'SUCCEEDED': 'success', 'UNPROCESSABLE': 'secondary', 'SCHEDULED': 'secondary' }
        return (
            <div className="tab-container">
                {this.props.loading ? <Loader /> : <Row>
                    <Col xs={12}>
                        <Row>
                            <Col className="balance-box-wrapper">
                                <Row>
                                    <Col>
                                        {driver && driver.branch && driver.branch.tenant && driver.branch.tenant.customId === "mnave" ?
                                            <h2 className={driver && driver.bcBalance > 0 ? "columnContent danger" : "columnContent success"}>{"$" + (driver && driver.bcBalance)}</h2> :
                                            <h2 className={driver && driver.balance > 0 ? "columnContent danger" : "columnContent success"}>{"$" + (driver && driver.balance)}</h2>
                                        }
                                        <p className="balance-box-wrapper-text">TOTAL BALANCE</p>
                                    </Col>
                                    <Col className="text-right">
                                        <span className="balance-box-wrapper-icon"><i className="fa fa-usd" aria-hidden="true"></i></span>
                                        <br />
                                        <UncontrolledTooltip placement="left" target="refetch_balance">Refresh Balance</UncontrolledTooltip>
                                        <i id="refetch_balance" className="fa fa-rotate-right" onClick={this.refetchBalance}></i>&nbsp;&nbsp;
                                    </Col>
                                </Row>
                            </Col>
                            {driver.balanceWriteOff < 0 &&
                                <Col className="balance-box-wrapper">
                                    <Row>
                                        <Col>
                                            <h2 className="columnContent danger">${driver.balanceWriteOff * -1}</h2>
                                            <p className="balance-box-wrapper-text">BALANCE WRITE OFF</p>
                                        </Col>
                                        <Col className="text-right">
                                            <span className="balance-box-wrapper-icon" > <i className="fa fa-line-chart" aria-hidden="true" style={{ color: "red" }}></i></span>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {driver.creditWriteOff > 0 &&
                                <Col className="balance-box-wrapper">
                                    <Row>
                                        <Col>
                                            <h2 className="columnContent success">${driver && driver.creditWriteOff}</h2>
                                            <p className="balance-box-wrapper-text">CREDIT WRITE OFF</p>
                                        </Col>
                                        <Col className="text-right">
                                            <span className="balance-box-wrapper-icon"> <i className="fa fa-area-chart" aria-hidden="true" style={{ color: "green" }}></i></span>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col className="balance-box-wrapper">
                                <Row>
                                    <Col>
                                        <h2 className={driver && driver.currentCharge > 0 ? "columnContent danger" : "columnContent success"}>{"$" + (driver && driver.currentCharge)}</h2>
                                        <p className="balance-box-wrapper-text">CURRENT CHARGE</p>
                                    </Col>
                                    <Col className="text-right">
                                        <span className="balance-box-wrapper-icon"><i className="fa fa-pie-chart" aria-hidden="true"></i></span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="balance-box-wrapper">
                                <Row>
                                    <Col>
                                        <h2 className="columnContent">{"$" + (driver && driver.deposit)}</h2>
                                        <p className="balance-box-wrapper-text">DEPOSIT</p>
                                    </Col>
                                    <Col className="text-right">
                                        <span className="balance-box-wrapper-icon" > <img src={securityDepositIcon} width={60} height={60} lt="security_deposit logo" /></span>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <h5>BILLING INFO</h5>
                                <div className="info-container">
                                    <Row>
                                        <Col xs={4}>
                                            <Label><span onClick={() => this.toggleModal("paymentMethodEdit")}>Payment Method <i className="fa fa-pencil-square" id="paymentMethodEdit" aria-hidden="true"> </i></span></Label>
                                            <p className="columnContent">{driver && driver.paymentMethod && driver.paymentMethod.name}</p>
                                            {driver && this.state.openModal === "paymentMethodEdit" &&
                                                <UpdateDriverInfo driverId={driver.id} tenant={driver && driver.branch && driver.branch.tenant && driver.branch.tenant.customId} title="Edit Payment Method" name="paymentMethod" value={driver.paymentMethod && driver.paymentMethod.name}
                                                    target={"paymentMethodEdit"} id="paymentMethod" type="text" open={this.state.openModal === "paymentMethodEdit"}
                                                    handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery}
                                                />
                                            }
                                        </Col>
                                        <Col xs={4}>
                                            <Label><span>Billing Day {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_update_invoice_day") && <i id="chargeDayofweekEdit" onClick={() => this.toggleModal("chargeDayofweekEdit")} className="fa fa-pencil-square" aria-hidden="true"> </i>} &nbsp; <i id="chargeDayofWeekHistory" onClick={() => this.toggleModal("chargeDayofWeekHistory")} className={"fa fa-history"}></i></span></Label>
                                            <Popover className="charge-day-history" placement="bottom" toggle={() => this.toggleModal("")} isOpen={this.state.openModal === "chargeDayofWeekHistory"} target="chargeDayofWeekHistory">
                                                <PopoverHeader onClick={this.props.handleClose} className="d-flex justify-content-center">Billing Day History</PopoverHeader>
                                                <PopoverBody>
                                                    <div>
                                                        <Row>
                                                            <Col xs={3}>
                                                                <b>Date</b>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <b>Update</b>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <b>Updated By</b>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        {driver && driver.history && driver.history.length > 0 && driver.history.filter(item => item.comment.toLowerCase().includes("charge day")) ? driver.history.filter(item => item.comment.toLowerCase().includes("charge day")).map((history, i) =>
                                                            <Row key={i}>
                                                                <Col xs={3}>
                                                                    <p className="columnContent">{history.date ? <DatetimeRenderer datetime={history.date} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <p className="columnContent">{history.comment || ""}</p>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <p className="columnContent">{history.user || ""}</p>
                                                                </Col>
                                                            </Row>
                                                        ) : !this.state.loading && "No Charge Day History Found!"}
                                                    </div>
                                                    <hr />
                                                    <Row>
                                                        <Col className="text-rigth">
                                                            <Button className="float-right close_history" type="button" size="sm" onClick={() => this.toggleModal("")}>Close</Button>
                                                        </Col>
                                                    </Row>
                                                </PopoverBody>
                                            </Popover>
                                            <p className="columnContent">
                                                {driver && BILLING_DAYS.filter((day, i) => day.value === driver.chargeDayofweek).map((day, i) => <span key={i}>{day.label}</span>)} {driver.collectionDay !== driver.chargeDayofweek && <>
                                                    <UncontrolledTooltip target="collectionDay">Collection Day</UncontrolledTooltip>
                                                    <span id="collectionDay">{BILLING_DAYS.filter((day, i) => day.value === driver.collectionDay).map((day, i) => "-> " + day.label)}</span>
                                                </>}
                                            </p>
                                            {driver && this.state.openModal === "chargeDayofweekEdit" &&
                                                <UpdateDriverInfo driverId={driver.id} title="Edit Billing Day" name="chargeDayofweek" value={driver.chargeDayofweek}
                                                    target={"chargeDayofweekEdit"} id="chargeDayofweek" type="text" open={this.state.openModal === "chargeDayofweekEdit"}
                                                    handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery}
                                                />
                                            }
                                        </Col >
                                        <Col xs={4} id="chargeDayofweekEdit">
                                            <Label >Billing Cycle</Label>
                                            <p className="columnContent">
                                                <span>
                                                    {driver && driver.currentAgreement && driver.currentAgreement.agreementpriceSet && driver.currentAgreement.agreementpriceSet.edges && driver.currentAgreement.agreementpriceSet.edges.length > 0 && driver.currentAgreement.agreementpriceSet.edges[0].node && driver.currentAgreement.agreementpriceSet.edges[0].node.intervalUnit && driver.currentAgreement.agreementpriceSet.edges[0].node.intervalUnit.toLowerCase() === 'week' ?
                                                        BILLING_DAYS.filter((day, i) => day.value === driver.chargeDayofweek).map((day, i) => day.label + " - " + (day.value === 0 ? "Sunday" : BILLING_DAYS.filter(firstDay => firstDay.value + 1 === day.value).map(firstDay => firstDay.label)))
                                                        : "--"
                                                    }
                                                </span>
                                            </p>
                                        </Col>
                                        <Col xs={4}>
                                            <Label>Auto Charge <i onClick={() => this.toggleModal("autoCharge")} id="autoCharge" className="fa fa-pencil-square" aria-hidden="true"></i></Label>
                                            <p className="columnContent">
                                                <i className={driver && driver.autoCharge === true ? "fa fa-toggle-on switch text-success" : "fa fa-toggle-off switch text-danger"} aria-hidden="true"></i>
                                            </p>
                                            {driver && this.state.openModal === "autoCharge" &&
                                                <UpdateDriverInfo driverId={driver.id} title="Edit Auto Cahrge" name="autoCharge" value={driver.autoCharge}
                                                    target={"autoCharge"} id="weeklyBillingNotes" type="boolean" open={this.state.openModal === "autoCharge"}
                                                    handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery}
                                                />
                                            }
                                        </Col>
                                        <Col xs={4}>
                                            <Label>Charge Toll Fee <i onClick={() => this.toggleModal("chargeTollFee")} id="chargeTollFee" className="fa fa-pencil-square" aria-hidden="true"></i></Label>
                                            <p className="columnContent">
                                                <i className={driver && driver.chargeTollFee === true ? "fa fa-toggle-on switch text-success" : "fa fa-toggle-off switch text-danger"} aria-hidden="true"></i>

                                            </p>
                                            {driver && this.state.openModal === "chargeTollFee" &&
                                                <UpdateDriverInfo driverId={driver.id} title="Edit Charge Toll Fee" name="chargeTollFee" value={driver.chargeTollFee}
                                                    target={"chargeTollFee"} id="chargeTollFee" type="boolean" open={this.state.openModal === "chargeTollFee"}
                                                    handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery}
                                                />
                                            }
                                        </Col>
                                        <Col xs={4}>
                                            <Label>CC Surcharge <i onClick={() => this.toggleModal("chargeCcsurcharge")} id="chargeCcsurcharge" className="fa fa-pencil-square" aria-hidden="true"></i></Label>
                                            <p className="columnContent">
                                                <i className={driver && driver.chargeCcsurcharge === true ? "fa fa-toggle-on switch text-success" : "fa fa-toggle-off switch text-danger"} aria-hidden="true"></i>
                                            </p>
                                            {driver && this.state.openModal === "chargeCcsurcharge" &&
                                                <UpdateDriverInfo driverId={driver.id} title="Edit Billing Notes" name="chargeCcsurcharge" value={driver.chargeCcsurcharge}
                                                    target={"chargeCcsurcharge"} id="chargeCcsurcharge" type="boolean" open={this.state.openModal === "chargeCcsurcharge"}
                                                    handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery}
                                                />
                                            }
                                        </Col>
                                        <Col xs={4}>
                                            <Label>Remote Cash</Label>
                                            <p className="columnContent">
                                                {driver && <a target="_blank" href={"https://www.achpsac.com/buggy/barcode/" + driver.pk + "?C=B34*5"}>Remote Cash Link <i className={"fa fa-external-link-square"}></i></a>}
                                            </p>
                                        </Col>
                                        <Col xs={4}>
                                            <Label>Deductible Amount</Label>
                                            <p className="columnContent">
                                                {driver && (driver.deductible !== 0 || subscriptionDeductibleAmount == 0) && <span style={{ "text-decoration": subscriptionDeductibleAmount !== 0 && driver.deductible !== 0 ? "line-through" : "none" }}>{driver.deductible !== 0 ? "$" + driver.deductible : "--"}</span>}&nbsp;{subscriptionDeductibleAmount !== 0 && <Link to={`/driver/${driver.id}/#risk`}>{"$" + subscriptionDeductibleAmount}</Link>}
                                            </p>
                                        </Col>
                                        {
                                            driver && driver.hasTokuPaymentReferences && (
                                                <Col xs={4}>
                                                    <Label>Resend payment instructions</Label>
                                                    <Button onClick={() => this.sendPaymentInstructions()} size="sm" className="primary">Send</Button>
                                                </Col>
                                            )
                                        }
                                        <Col xs={8}>
                                            <Label><span>Billing Notes <i id="weeklyBillingNotesHistory" onClick={() => this.toggleModal("weeklyBillingNotesHistory")} className={"fa fa-pencil-square"}></i></span></Label>
                                            <p className="columnContent">{driver ? driver.weeklyBillingNotes : ""}</p>
                                            {driver && this.state.openModal === "weeklyBillingNotesHistory" &&
                                                <NotesHistory
                                                    driverId={driver ? driver.id : null}
                                                    id={"weeklyBillingNotesHistory"}
                                                    target={"weeklyBillingNotesHistory"}
                                                    noteType="BILLING"
                                                    title="Billing Notes History"
                                                    open={this.state.openModal === "weeklyBillingNotesHistory"}
                                                    name={"weeklyBillingNotes"}
                                                    handleClose={() => this.toggleModal("")}
                                                    objectType={"driverNotes"}
                                                    objectId={""}
                                                    refetchQuery={() => window.location.reload()}
                                                />
                                            }
                                        </Col>
                                    </Row >
                                </div >
                            </Col >
                            <Col>
                                {driver.paymentscheduleSet && driver.paymentscheduleSet.edges && driver.paymentscheduleSet.edges.length > 0 && <>
                                    <h5>SCHEDULED PAYMENT</h5>
                                    <div className="info-container" style={{ maxHeight: "150px", overflow: "auto" }}>
                                        <Row>
                                            <Col><Label>Scheduled Time</Label></Col>
                                            <Col><Label>Amount</Label></Col>
                                            <Col><Label>Status</Label></Col>
                                            <Col><Label>Actions</Label></Col>
                                        </Row>
                                        {driver.paymentscheduleSet.edges.length > 0 ? driver.paymentscheduleSet.edges.sort((a, b) => new Date(b.node.dateAdded) - new Date(a.node.dateAdded)).map((paymentschedule, i) =>
                                            <Row>
                                                <Col>
                                                    <p className="columnContent">
                                                        {paymentschedule.node ? <DatetimeRenderer datetime={paymentschedule.node.scheduleDatetime} placement="right" format={"MMMM Do YYYY, h:mm a"} /> : '--'}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="columnContent">
                                                        ${paymentschedule.node.amountToCharge}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="columnContent">
                                                        <Badge color={PAYMENT_SCHEDULE_STATUS_COLOR_MAP[paymentschedule.node.status] || 'primary'} style={{ fontSize: 10, padding: 5 }}>{paymentschedule.node.status}</Badge>
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="columnContent">
                                                        {this.props.hasPermissions && this.props.hasPermissions.includes("change_paymentschedule") &&
                                                            <>
                                                                &nbsp;&nbsp;<i onClick={() => this.toggleModal("updatePaymentSchedule" + i)} id={"updatePaymentSchedule" + i} className="fa fa-pencil-square" aria-hidden="true"></i>
                                                                <UncontrolledTooltip target={"updatePaymentSchedule" + i}>Edit scheduled charge</UncontrolledTooltip>
                                                            </>
                                                        }
                                                        &nbsp;&nbsp;<i id={"paymentScheduleHistory" + i} onClick={() => this.toggleModal("paymentScheduleHistory" + i)} className={"fa fa-history"}></i>
                                                        <UncontrolledTooltip target={"paymentScheduleHistory" + i}>History</UncontrolledTooltip>

                                                        {this.state.openModal === "paymentScheduleHistory" + i &&
                                                            <Popover className="notes_history" placement={"right"} isOpen={this.state.openModal === "paymentScheduleHistory" + i} target={"paymentScheduleHistory" + i} toggle={() => this.toggleModal("")}>
                                                                <PopoverHeader onClick={() => this.toggleModal("")} className="d-flex justify-content-center">Scheduled Charge Update History</PopoverHeader>
                                                                <PopoverBody>
                                                                    {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                                                                    <div className="notes_container">
                                                                        {!this.state.loading && paymentschedule.node.history && paymentschedule.node.history.length > 0 ? paymentschedule.node.history.map((history, i) =>
                                                                            <p key={i}>
                                                                                <b>Date Modified: {<DatetimeRenderer datetime={history.date} />}</b><br />
                                                                                <b>Username: {history.user ? history.user : "--"}<br /></b>
                                                                                <b>Comment: </b>{history.comment}
                                                                            </p>
                                                                        ) : !this.state.loading && "No Update History Found!"}
                                                                    </div>
                                                                    <hr />
                                                                </PopoverBody>
                                                            </Popover>
                                                        }
                                                        {this.state.openModal === "updatePaymentSchedule" + i &&
                                                            <PaymentScheduleModal
                                                                paymentScheduleId={paymentschedule.node.id}
                                                                handleClose={() => this.toggleModal("")}
                                                                open={this.state.openModal === "updatePaymentSchedule" + i}
                                                                refetchQuery={this.props.refetchDriverQuery}
                                                                action={"UPDATE"}
                                                            />
                                                        }
                                                    </p>
                                                </Col >
                                            </Row>
                                        ) : <p>No scheduled payment found!</p>
                                        }
                                    </div>
                                </>}
                                {driver && driver.paymentplanSet && driver.paymentplanSet.edges && driver.paymentplanSet.edges.length > 0 && <>
                                    <h5>ACTIVE PLANS</h5>
                                    <div className="info-container">
                                        <Row>
                                            {driver.paymentplanSet.edges.map((plan, i) =>
                                                <Fragment key={i}>
                                                    <Col>
                                                        <Label>Plan Details</Label>
                                                        <p className="columnContent">${plan.node.paydownOriginalBalance}/{Math.round(plan.node.paydownOriginalBalance / plan.node.paydownAmount)}=${plan.node.paydownAmount}</p>
                                                    </Col>
                                                    <Col>
                                                        <Label>Start Date</Label>
                                                        <p className="columnContent">{plan.node.paydownStartDate ? <DatetimeRenderer datetime={plan.node.paydownStartDate} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                                    </Col>
                                                    <Col>
                                                        <Label>Actions</Label>
                                                        <p className="columnContent">
                                                            <i id={"editPaymentPlanInfo" + i} onClick={() => this.toggleModal("PaymentPlanInfo" + i)} className={"fa fa-pencil-square"}></i>
                                                            <UncontrolledTooltip target={"editPaymentPlanInfo" + i}>Edit Plan</UncontrolledTooltip>
                                                            &nbsp;&nbsp;<i id="paymentPlanHistory" onClick={() => this.toggleModal("paymentPlanHistory")} className={"fa fa-history"}></i>
                                                            <UncontrolledTooltip target="paymentPlanHistory">History</UncontrolledTooltip>
                                                        </p>
                                                        {this.state.openModal === "PaymentPlanInfo" + i &&
                                                            <PaymentPlanInfo handleClose={() => this.toggleModal("")} open={this.state.openModal === "PaymentPlanInfo" + i} id={plan.node.id} refetchQuery={this.props.refetchDriverQuery} />
                                                        }
                                                        {this.state.openModal === "paymentPlanHistory" &&
                                                            <Popover className="notes_history" placement={"right"} isOpen={this.state.openModal === "paymentPlanHistory"} target={"paymentPlanHistory"} toggle={() => this.toggleModal("")}>
                                                                <PopoverHeader onClick={() => this.toggleModal("")} className="d-flex justify-content-center">Payment Plan Update History</PopoverHeader>
                                                                <PopoverBody>
                                                                    {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                                                                    <div className="notes_container">
                                                                        {!this.state.loading && plan.node.history && plan.node.history.length > 0 ? plan.node.history.map((history, i) =>
                                                                            <p key={i}>
                                                                                <b>Date Modified: {<DatetimeRenderer datetime={history.date} />}</b><br />
                                                                                <b>Username: {history.user ? history.user : "--"}<br /></b>
                                                                                <b>Comment: </b>{history.comment}
                                                                            </p>
                                                                        ) : !this.state.loading && "No Update History Found!"}
                                                                    </div>
                                                                    <hr />
                                                                </PopoverBody>
                                                            </Popover>
                                                        }
                                                    </Col>
                                                </Fragment>
                                            )}
                                        </Row>
                                    </div>
                                </>}
                            </Col>
                        </Row>
                    </Col>
                </Row>}
                {driver && driver.branch && driver.branch.tenant && driver.branch.tenant.customId !== "mnave" &&
                    <div className="driver-detail mt-4">
                        <Nav tabs>
                            <NavItem className={this.state.subTab === "transactions" ? "active" : "inactive"}>
                                <NavLink className={this.state.subTab === "transactions" ? "active" : "inactive"} onClick={() => this.changeTab("transactions")}>
                                    TRANSACTIONS
                                </NavLink>
                            </NavItem>
                            <NavItem className={this.state.subTab === "invoices" ? "active" : "inactive"}>
                                <NavLink className={this.state.subTab === "invoices" ? "active" : "inactive"} onClick={() => this.changeTab("invoices")}>
                                    INVOICES
                                </NavLink>
                            </NavItem>
                        </Nav>
                        {this.state.subTab === "transactions" && <TransactionListContainer activeTab={this.state.subTab} driverId={this.props.id} refetchQuery={() => { this.props.refetchDriverQuery() }} currentUser={this.props.currentUser} {...this.props} />}
                        {this.state.subTab === "invoices" && <InvoiceTab driverId={this.props.id} currentUser={this.props.currentUser} />}
                    </div>
                }
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(DriverQuery, {
        options: ({ id }) => ({ variables: { id }, fetchPolicy: 'network-only' }),
        props({ data: { driver, loading, refetch, variables } }) {
            return {
                driver, loading, variables,
                refetchDriverQuery: () => {
                    return refetch({
                        query: DriverQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { driver: fetchMoreResult.driver }
                        },
                    })
                },
            }
        }
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_create_transaction", "custom_can_update_invoice_day", "change_paymentschedule", "delete_paymentschedule"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, variables } }) {
            return { hasPermissions, variables }
        }
    })
)(BillingTab)
